import { createSlice } from "@reduxjs/toolkit";
import { createResetState, creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { ArrangeInvigilationTask } from "@/services/arrangeInvigilation";
import { DIVIDE_INVIGILATE_CURRENT_TASK } from "@/config/constants";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from "dayjs";

export interface ExamSettingRecord {
  id: string;
  examGrade: string;
  examClass: string;
  roomNumber: string;
  [key: string]: string;
}

export interface dateTimeObj {
  examStartTime?: number | null | Dayjs;
  examEndTime?: number | null | Dayjs;
  date?: number | null | Dayjs;
  isEditDate?: boolean;
  isEditTime?: boolean;
}

interface ArrangeInvigilationInitialState {
  currentTask: ArrangeInvigilationTask | undefined;
  examSettingData: ExamSettingRecord[];
  examSettingModifyedData: ExamSettingRecord[];
  dateTimeMap: Map<string, dateTimeObj>;
  modifyedDateTimeMap: Map<string, dateTimeObj>;
  teacherCount: number;
  modifyedTeacherCount: number;
}

let defaultCurrentTask: ArrangeInvigilationTask | undefined;
try {
  defaultCurrentTask = JSON.parse(localStorage.getItem(DIVIDE_INVIGILATE_CURRENT_TASK) || "null");
} catch (e) {
  console.log(e);
  localStorage.removeItem(DIVIDE_INVIGILATE_CURRENT_TASK);
}

const initialState: ArrangeInvigilationInitialState = {
  currentTask: defaultCurrentTask || undefined,
  // 考试设置基础数据 接口初始化数据
  examSettingData: [
    {
      id: uuidv4(),
      examGrade: "一年级",
      examClass: "一年级一班",
      roomNumber: "1001",
    },
  ],
  // 已修改过的数据
  examSettingModifyedData: [
    {
      id: uuidv4(),
      examGrade: "一年级",
      examClass: "一年级一班",
      roomNumber: "1001",
    },
  ],
  // 日期时间存储值
  dateTimeMap: new Map(),
  // 已修改过日期时间存储值
  modifyedDateTimeMap: new Map(),
  teacherCount: 1,
  modifyedTeacherCount: 1,
};

const arrangeInvigilationSlice = createSlice({
  name: "arrangeInvigilation",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(initialState),
  },
});

export const setArrangeInvigilationState = creatSetState("arrangeInvigilation");
export const resetArrangeInvigilationState = createResetState("arrangeInvigilation");

export default arrangeInvigilationSlice;
