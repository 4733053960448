import {
  APP_URL,
  JW_APP_URL,
  SCHOOL_INFO,
  USER_TOKEN,
  USER_ID,
  WECOM_CORP_ID,
} from "@/config/constants";
import { isFromWelink } from "@/layouts/hooks/useIsFromWelink";
import { getBackUrl } from "@/utils/toolUtls";
import { isJwInlandApp, isJwIntlApp } from "@/utils/app/env";

function getCookieMap() {
  return document.cookie.split(";").reduce((map, item) => {
    const tokens = item.split("=");
    map[tokens[0].trim()] = tokens[1];
    return map;
  }, {} as Record<string, string>);
}

function getEffectiveDomain() {
  return window.location.hostname.split(".").slice(-2).join(".");
}

// 设置token到cookie
export function setBaseToken2cookie(memberToken: string) {
  document.cookie = `baseToken=${memberToken};domain=${getEffectiveDomain()};path=/`;
}

// 移除cookie中的token
export function removeBaseToken2Cookie() {
  document.cookie = `baseToken="";domain=${getEffectiveDomain()};max-age=-1;path=/`;
}

// 从cookie获取token
export function getBaseToken2cookie() {
  return getCookieMap()["baseToken"];
}

// 获取token
export function getUserToken() {
  const baseToken = getBaseToken2cookie();
  // 存在通用token，保存到本地存储并返回；否则返回本地token
  if (baseToken) {
    localStorage.setItem(USER_TOKEN, baseToken);
    return baseToken;
  }
  return localStorage.getItem(USER_TOKEN);
}

export function removeUserTokenWithRelation() {
  removeBaseToken2Cookie();

  localStorage.removeItem(USER_TOKEN);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(SCHOOL_INFO);
  localStorage.removeItem(WECOM_CORP_ID);
  sessionStorage.removeItem("isFromWelink");
}

export function logout() {
  removeUserTokenWithRelation();

  if (isFromWelink()) {
    window.location.href = `${APP_URL}#/welink-login`;
    return;
  }

  if (isJwInlandApp || isJwIntlApp) {
    window.location.href = `${JW_APP_URL}#/login?backApp=kuaipk&backUrl=${getBackUrl()}`;
    return;
  }

  window.location.href = `${APP_URL}#/login?backUrl=${getBackUrl()}`;
}
