import { isJwInlandApp, isJwIntlApp } from "./env";

export default function genStorageKeyByAppType(key: string) {
  if (isJwIntlApp) {
    return `jw_intl_${key}`;
  }
  if (isJwInlandApp) {
    return `jw_inland_${key}`;
  }
  return `kuaipk_${key}`;
}
