import { createSlice } from "@reduxjs/toolkit";
import { creatSetState, mapBaseReducers } from "@/utils/reduxUtils";
import { User } from "@/types";
import { School, SchoolInfo } from "@/services/school";
import { IUserVipInfo } from "@/services/user";
import { CourseScheduleRecord } from "@/services/courseScheduling/scheduleList";
import { ScheduleRuleSatisfaction } from "@/services/courseScheduling/settingRules";

export interface UserState {
  isLogin: boolean;
  hasInit: boolean;
  userInfo?: User;
  school?: School;
  schoolWithName?: SchoolInfo;
  scheduleScheme?: CourseScheduleRecord;
  customResolveNext: boolean;
  needResolveNext: boolean;
  hasScheduled: boolean;
  openLoginModal: boolean;
  accessPerms: string[];
  accessUrls: string[];
  userVipInfo?: IUserVipInfo;
  willInTeamwork?: boolean; //
  scheduleRulesSatisfactionMap: Record<string, ScheduleRuleSatisfaction> | undefined;
  stepsIsExpanded?: boolean;
  shouldFocusSchedule: string | undefined;
}

const initialState: UserState = {
  isLogin: false,
  hasInit: false,
  userInfo: undefined,
  school: undefined,
  schoolWithName: undefined,
  scheduleScheme: undefined,
  customResolveNext: false, // 页面自行处理下一步逻辑
  needResolveNext: false, // 页面需要处理下一步逻辑
  hasScheduled: true, // 是否已经排课，默认为true不触发自动排
  openLoginModal: false, // 打开登录弹窗
  accessPerms: [], // 用户功能权限标识符列表
  accessUrls: [], // 用户页面权限标识符列表
  userVipInfo: undefined,
  willInTeamwork: false, // 即将进入协同模式
  scheduleRulesSatisfactionMap: undefined,
  stepsIsExpanded: true,
  shouldFocusSchedule: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    ...mapBaseReducers(),
  },
});

export const setUserState = creatSetState("user");

export default userSlice;
